import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import "./MobileSummary.css";
import "./PaymentPage.scss";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import { dummy } from "./Data";
import { serverUrl } from "../../ApiUrl";
import { ToastContainer } from "react-toastify";
import { CaretDown } from "react-bootstrap-icons";

import "react-toastify/dist/ReactToastify.css";
import { initiateCheckout, pageView, trackContact } from "../../utils/fb";
import { PlanCost, cityArray } from "./MobileData";
import arrowWhite from "../../assets/arrow/arrow-left.png";
import arrowBlack from "../../assets/arrow/arrow-left-black.png";
import { installationCoast } from "./webApp/Data/data";
import PlanDetail from "./webApp/comom/PlanDetail/PlanDetail";
import {
  consumeVocherCodeApi,
  getVoucherStatusApi,
  registerVoucherClient,
} from "../ApiCalls/getVoucherStatus";
import Captcha from "./Captcha";
import userIcon from "../../assets/V4/inputBox/profile.png";
import contactIcon from "../../assets/V4/inputBox/contact.png";
import emailIcon from "../../assets/V4/inputBox/email.png";
import locationIcon from "../../assets/V4/inputBox/location.png";
import couponIcon from "../../assets/V4/inputBox/coupon_code.png";
import disclamirIcon from "../../assets/V4/inputBox/disclamer.png";
import tickIcon from "../../assets/V4/inputBox/tick.png";
import Header from "./webApp/comom/Header/Header";
import { Footer } from "./Main";

export const registerLeadAndLinkApi = async(data,setWabLoad)=>{
  setWabLoad(true)
  await axios.post(`${serverUrl.u}/api/zoho/registerLeadAndLink`,data).then(function (response) {
     window.location = response.data.data.partialPaymentDetails.short_url;
    setTimeout(function () {
      setWabLoad(false);
    }, 2000);
  })
  .catch(function () {
    setWabLoad(false)
  });
};


const PayButton = ({
  botherrorE,
  botherrorP,
  location,
  wabLoad,
  email,
  number,
  setCityError,
  setNameError,
  handleOnChange,
  checkNumber,
  captchaEnter,
  captcha,
  voucherData,
  consumeVocherCode,
  registerLeadAndLink,
  data,
  city,
  setCaptchaError,
  voucherCodePhone,
  name,
  match,
}) => {
  return (
    <>
      {botherrorE === true && botherrorP === true ? (
        <div
          className="mSummary-pay-button-container cursor-pointer"
          style={{
            zIndex: "4444444444444444444444",
            background:
              location.state !== null
                ? location.state.path === "3" || location.state.path === "4"
                  ? !match
                    ? wabLoad === true
                      ? "#cccccc"
                      : "#2050FF"
                    : "white"
                  : "black"
                : !match
                ? wabLoad === true
                  ? "#cccccc"
                  : "#2050FF"
                : "white",
          }}
        >
          <div
            className="mSummary-pay-button cursor-pointer"
            style={{
              backgroundColor: `${wabLoad === true ? "#cccccc" : "#2050FF"}`,
            }}
            onClick={() => {
              if (city === "Select City") {
                setCityError(true);
                handleOnChange(email);
                checkNumber(number);
                if (name === "") {
                  setNameError(true);
                  return;
                }
                return;
              }
              if (name === "") {
                setNameError(true);
                return;
              }

              if (Object.keys(voucherData).length !== 0) {
                if (captcha === captchaEnter) {
                  setCaptchaError(false);
                  consumeVocherCode(voucherCodePhone);
                } else {
                  setCaptchaError(true);
                  return;
                }
              } else {
                registerLeadAndLink();
              }
              trackContact({
                name,
                email,
                phone: number,
                city,
              });
              initiateCheckout({
                appliances: data.appliances,
                totalAmount: Number(
                  (
                    (data?.rate + installationCoast(data.appliances)) * 1.18 +
                    data?.appliances * 0
                  ).toFixed(2)
                ),
                planId: "Monthly",
                packageId: data.planId,
                fee: data?.rate,
              });
            }}
          >
            <div
              className="mSummary-pay-button-text cursor-pointer"
              style={{
                color:
                  location.state !== null
                    ? location.state.path === "3" || location.state.path === "4"
                      ? "white"
                      : "black"
                    : "white",
              }}
            >
              {Object.keys(voucherData).length !== 0 ? "Register" : "Pay ₹ 100"}
            </div>
          </div>
        </div>
      ) : (
        <div
          className="mSummary-pay-button-container cursor-pointer"
          style={{
            background:
              location.state !== null
                ? location.state.path === "3" || location.state.path === "4"
                  ? match
                    ? "white"
                    : "#cbcbcb"
                  : "black"
                : match
                ? "white"
                : "#cbcbcb",
          }}
        >
          <div
            className="mSummary-pay-button cursor-pointer"
            style={{
              backgroundColor: match && "rgb(213 222 255 / 30%)",
            }}
            onClick={() => {
              if (city === "Select City") {
                setCityError(true);
                checkNumber(number);
                handleOnChange(email);

                if (name === "") {
                  setNameError(true);
                  return;
                }
                return;
              }
              if (name === "") {
                setNameError(true);
                return;
              }
              if (email === "") {
                handleOnChange(email);
              }
            }}
          >
            <div
              className="mSummary-pay-button-text cursor-pointer"
              style={{
                color:
                  location.state !== null
                    ? location.state.path === "3" || location.state.path === "4"
                      ? "black"
                      : "white"
                    : "white",
              }}
            >
              {Object.keys(voucherData).length !== 0 ? "Register" : "Pay ₹ 100"}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export const isValidNumber = (number) => {
  let num = number;
  if (String(number).startsWith("+")) {
    // if the number starts with a plus accept it as is
    if (!String(number).startsWith("+91")) return false;
    num = String(number).replace("+91", "");
  }
  // the number does not start with + assume its an indian number and process accordingly
  num = String(num).replace(/\D/g, "");
  while (num.startsWith("0")) {
    num = num.slice(1, num.length);
  }
  return num.length === 10;
};

export default function MobileSummary({ pageshow = true }) {
  const location = useLocation();
  let data = useSelector((state) => state.mobilePlanData);
  let questionUser = useSelector((state) => state.questionUser);
  const randomString = Math.random().toString(36).slice(8);
  const [captcha, setCaptcha] = useState(randomString);
  const [captchaEnter, setCaptchaEnter] = useState("");
  let [installation, setInsallation] = useState(300);
  let [wabLoad, setWabLoad] = useState(false);
  let [number, setNumber] = useState("");
  let [name, setName] = useState("");
  let [nameError, setNameError] = useState(false);
  let [emailError, setEmailError] = useState(false);
  let [phoneError, setPhoneError] = useState(false);
  let [captchaError, setCaptchaError] = useState(false);
  let [botherrorE, setBothErrorE] = useState(false);
  let [botherrorP, setBothErrorP] = useState(false);
  let [applyBtn, setApplyBtn] = useState("Apply");
  let [voucherError, setVoucherError] = useState("");
  let [voucherConsumeError, setVoucherConsumeError] = useState("");
  let [voucherData, setVoucherData] = useState({});
  let [voucherDiscount, setVoucherDiscount] = useState("");
  let [voucherDisclamair, setVoucherDisclamair] = useState("");
  let [selectPopMobile, setSelectPopMobile] = useState(false);
  let [selectPopDesktopCity, setSelectPopDesktopCity] = useState(false);
  let [selectPopMobileCity, setSelectPopMobileCity] = useState(false);
  let [defaultSelectedPlan, setDefaultSelectedPlan] = useState(
    location.state !== null
      ? location.state.hasOwnProperty("cost") === true
        ? location.state.cost
        : PlanCost[1]
      : PlanCost[1]
  );
  let [city, setCity] = useState("Select City");
  let [cityError, setCityError] = useState(false);
  let [Discount, setDiscount] = useState(0);
  const [captchaSuccess, setCaptchaSuccess] = useState(false);
  let voucherCodePhone = useRef(null);
  let [email, setEmail] = useState("");
  let [vhs, setVh] = useState(0);
  useEffect(() => {
    if (Object.keys(questionUser).length > 2) {
      setName(questionUser.name);
      setNumber(questionUser.phone);
      setCity(questionUser.city);
    }
  }, [questionUser]);
  useEffect(() => {
    if (data.appliances <= 10) {
      setInsallation(300);
    } else if (data.appliances > 10) {
      let app = data.appliances - 10;
      setInsallation(300 + app * 20);
    }
  }, [data]);

  useEffect(() => {
    pageView({ name: "Summary", location: window.location.toString() });
  }, []);

  const registerLeadAndLink = async () => {
    let res = handleOnChange(email);
    if (res === false) return;
    if (number.length <= 9) {
      setPhoneError(true);
      return;
    }
    
    await registerLeadAndLinkApi({
      planFrequency: `${defaultSelectedPlan.planName==="Monthly"?defaultSelectedPlan.planName:`${defaultSelectedPlan.planName}2`}`,
      planId: data.planId,
      email: email,
      refferal: data.refferal === undefined ? null : data.refferal,
      teleCallerId: "website",
      first_name: name,
      last_name: " ",
      phone: number,
      city: city.toUpperCase(),
      installation: installationCoast(data.appliances),
      security: data.appliances * 0,
      address: "No",
      layout: dummy[data.planId],
      leadQuality: "10",
      origin: "website",
      comments: " ",
      zip: "",
      partialPayment: true,
      partialAmount: 100,
      secondarySalesPerson: "empty",
      discount: 0,
      orignalRecursiveAmount:
        data?.appliances *
        defaultSelectedPlan.days *
        defaultSelectedPlan.cost,
      orignalTotalAmount: Number(
        (
          (data?.appliances *
            defaultSelectedPlan.days *
            defaultSelectedPlan.cost +
            installationCoast(data.appliances)) *
            1.18 +
          data?.appliances * 0
        ).toFixed(2)
      ),
      recursiveAmount:
        data?.appliances *
        defaultSelectedPlan.days *
        defaultSelectedPlan.cost,
      totalAmount: Number(
        (
          (data?.appliances *
            defaultSelectedPlan.days *
            defaultSelectedPlan.cost +
            installationCoast(data.appliances)) *
            1.18 +
          data?.appliances * 0
        ).toFixed(2)
      ),
      addOnAmount: 0,
      totalAppliances: data.appliances,
    },setWabLoad)
  }

  const checkNumber = (num) => {
    let res = isValidNumber(num);

    if (!res) {
      setPhoneError(true);
    } else if (res) {
      setBothErrorP(true);
      setPhoneError(false);
    }
    return res;
  };

  const getVoucherStataus = async (code) => {
    if (code === null) return;
    setApplyBtn("processing...");
    setVoucherError("");
    setVoucherDiscount("");
    setVoucherData({});
    setVoucherConsumeError("");
    setDiscount(0);
    setVoucherDisclamair("");

    let res = await getVoucherStatusApi(
      code,
      Number(
        (
          (data?.appliances * defaultSelectedPlan.days + installation) * 1.18 +
          data?.appliances * 10
        ).toFixed(2)
      )
    );
    if (res.success === false) {
      setVoucherError(res.message);
      setApplyBtn("Apply");
      setDiscount(0);
    } else if (res.success === true) {
      setVoucherData({});
      if (res.data.Status === "CONSUMED") {
        setVoucherDiscount("");
        setVoucherData({});
        setVoucherError("Voucher already redeemed");
        setApplyBtn("Apply");
        setDiscount(0);
      }
      if (res.data.Status === "VALID") {
        setVoucherError("");
        setVoucherData(res.data);
        setVoucherDiscount(`Voucher applied - INR  ${res.data.Value}  off `);
        setVoucherDisclamair(
          "If your final bill value is less than the voucher amount, the extra amount will be forfeited"
        );
        setDiscount(Number(res.data.Value));
        setApplyBtn("Apply");
      }
      if (res.data.Status === "INVALID") {
        setVoucherDiscount("");
        setVoucherData({});
        setVoucherError("Voucher is invalid");
        setApplyBtn("Apply");
        setDiscount(0);
      }
      if (res.data.Status === "EXPIRED") {
        setVoucherDiscount("");
        setVoucherData({});
        setVoucherError("Voucher is Expired");
        setApplyBtn("Apply");
        setDiscount(0);
      }
    }
  };
  const consumeVocherCode = async () => {
    setVoucherConsumeError("");
    let res = handleOnChange(email);
    if (res === false) return;
    if (number.length <= 9) {
      setPhoneError(true);
      return;
    }
    let ress = await consumeVocherCodeApi(
      email,
      number,
      voucherData.VoucherNumber,
      Number(
        (
          (data?.appliances * defaultSelectedPlan.days +
            installationCoast(data.appliances)) *
            1.18 +
          data?.appliances * 0
        ).toFixed(2)
      )
    );
    if (ress.success !== undefined && ress.success === false) {
      setVoucherData({});
      setVoucherConsumeError(ress.message);
      setVoucherDiscount("");
      return;
    }
    if (
      ress.createResponse !== undefined &&
      ress.createResponse.success === false
    ) {
      setVoucherData({});
      setVoucherConsumeError(ress.createResponse.message);
      setVoucherDiscount("");
      return;
    }
    if (ress.createResponse.success === true) {
      setWabLoad(true);

      let detail = {
        transactionId: ress.createResponse.data.voucherHistory.voucherCode,
        email: email,
        first_name: name,
        last_name: "",
        phone: number,
        city: "Any For Now",
        zone: "",
        address: "",
        zip: "",
        planId: data.planId,
        planFrequency: defaultSelectedPlan.planName,
        teleCallerId: "website",
        installation: installationCoast(data.appliances),
        security: data.appliances * 0,
        partialPayment: true,
        partialAmount: voucherData.Value,
        discount: 0,
        recursiveAmount:
          data?.appliances *
          defaultSelectedPlan.days *
          defaultSelectedPlan.cost,
        totalAmount: (
          (data?.appliances *
            defaultSelectedPlan.days *
            defaultSelectedPlan.cost +
            installationCoast(data.appliances)) *
            1.18 +
          data?.appliances * 0
        ).toFixed(2),
        addOnAmount: 0,
        totalAppliances: data?.appliances,
        ispSubscription: false,
      };
      await registerVoucherClient(detail);
      setWabLoad(false);
    }
  };

  let handleOnChange = (email) => {
    if (email === "") {
      setEmailError(true);
    }
    setEmail(email);

    if (
      email
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    ) {
      setEmailError(false);
      setBothErrorE(true);
      setEmail(email);
    } else {
      setEmailError(true);
      return false;
    }
  };
  useEffect(() => {
    if (captcha === captchaEnter) {
      setCaptchaError(false);
    }
  }, [captchaEnter]);
  useEffect(() => {
    let emailtest = false;
    let phonetest = false;
    if (
      email
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    ) {
      emailtest = true;
    } else {
      emailtest = false;
    }
    // if (number.length < 10) {
    //   phonetest = false
    // } else if (number.length === 10) {
    //   phonetest = true
    // } else {
    //   phonetest = false
    // }
    if (number === "") return;
    phonetest = checkNumber(number);
    if (phonetest === true && emailtest === true) {
      setBothErrorE(true);
      setBothErrorP(true);
    } else {
      setBothErrorE(emailtest);
      setBothErrorP(phonetest);
    }
  }, [email, number]);

  return (
    <div
      className={pageshow && "mSummary-top-container"}
      style={{
        background:
          location.state !== null
            ? location.state.path === "3" || location.state.path === "4"
              ? "rgb(243, 245, 250)"
              : "black"
            : "transparent",
      }}
    >
      {pageshow && (
        <>
          <ToastContainer />
          <Header isSticky={false} />
          <div className="mSummary-headder">
            <Link
              to={`/${
                location.state?.path !== undefined ? location.state.path : ""
              }`}
            >
              <div className="mSummary-arrow-container">
                <img
                  className="mSummary-arrow-img"
                  src={
                    location.state !== null
                      ? location.state.path === "3" ||
                        location.state.path === "4"
                        ? arrowBlack
                        : arrowWhite
                      : arrowWhite
                  }
                  alt={"Arrow left"}
                />
              </div>
            </Link>
            {/* <div className="mSummary-arrow-text" style={{
          background: location.state !== null ? ((location.state.path === "3" || location.state.path === "4") ? "rgb(243, 245, 250)" : "black") : "black",

        }}>
          <div style={{
            color: location.state !== null ? ((location.state.path === "3" || location.state.path === "4") ? "black" : "white") : "white"
          }}></div>
        </div> */}
          </div>
        </>
      )}
      <div className={pageshow && "containerSummary"}>
        <div className="topContainer">
          <div
            className="mSummary-top-summary"
            style={{
              background:
                location.state !== null
                  ? location.state.path === "3" || location.state.path === "4"
                    ? "rgb(243, 245, 250)"
                    : "black"
                  : "transparent",
            }}
          >
            <div
              className="mSummary-top-summary-inner-container"
              style={{
                background:
                  location.state !== null
                    ? location.state.path === "3" || location.state.path === "4"
                      ? "white"
                      : "#121212"
                    : "#FFF",
              }}
            >
              <div className="mSummary-Details">
                <div
                  className="mSummary-text-heading"
                  style={{
                    color:
                      location.state !== null
                        ? location.state.path === "3" ||
                          location.state.path === "4"
                          ? "black"
                          : "white"
                        : "black",
                  }}
                >
                  {data ? data.planName : "NOT Selected Plan"}
                </div>
                <div
                  className="mSummary-text-subheading"
                  style={{
                    color:
                      location.state !== null
                        ? location.state.path === "3" ||
                          location.state.path === "4"
                          ? "black"
                          : "white"
                        : "black",
                  }}
                >
                  Automate a total of {data?.appliances} appliances
                </div>
              </div>
              <div
                className="mSummary-price"
                style={{
                  color:
                    location.state !== null
                      ? location.state.path === "3" ||
                        location.state.path === "4"
                        ? "black"
                        : "white"
                      : "black",
                }}
              >
                ₹{" "}
                {data.appliances
                  ? data.appliances *
                    defaultSelectedPlan.days *
                    defaultSelectedPlan.cost
                  : 0}
              </div>
            </div>
          </div>

          <div
            className="mSummary-contact-form"
            style={{
              background:
                location.state !== null
                  ? location.state.path === "3" || location.state.path === "4"
                    ? "white"
                    : "#121212"
                  : "#FFF",
            }}
          >
            <form>
              <div
                className="mSummary-text-name"
                style={{
                  color:
                    location.state !== null
                      ? location.state.path === "3" ||
                        location.state.path === "4"
                        ? "black"
                        : "white"
                      : "black",
                }}
              >
                Personal details
              </div>
              <div
                className="input-container-img-inputBox"
                style={{
                  border:
                    location.state !== null
                      ? (location.state.path === "3" ||
                          location.state.path === "4") &&
                        nameError === false
                        ? "0.5px solid #00000054"
                        : "0.5px solid rgba(254, 0, 0)"
                      : "0.5px solid #00000054",
                }}
              >
                <div className="img-container">
                  <img
                    // src={process.env.PUBLIC_URL + "./MobileAssets/userIcon.png"}
                    src={userIcon}
                    alt={"name"}
                  />
                </div>
                <input
                  type="text"
                  className=""
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value);
                    if (e.target.value !== "") {
                      setNameError(false);
                    }
                  }}
                  placeholder="Your Name"
                  aria-label="name"
                  aria-describedby="basic-addon1"
                  style={{
                    color:
                      location.state !== null
                        ? location.state.path === "3" ||
                          location.state.path === "4"
                          ? "black"
                          : "white"
                        : "black",
                  }}
                />
              </div>
              {nameError === true ? (
                <div className="field-error">Please enter a valid Name.</div>
              ) : null}
              <div
                className="input-container-img-inputBox"
                onClick={() => setSelectPopMobileCity(!selectPopMobileCity)}
                style={{
                  border:
                    cityError === false
                      ? location.state !== null
                        ? location.state.path === "3" ||
                          location.state.path === "4"
                          ? "0.5px solid #00000054"
                          : "0.5px solid rgba(255, 255, 255, 0.8)"
                        : "0.5px solid #00000054"
                      : "0.5px solid #fe0000",
                }}
              >
                <div className="img-container">
                  <img
                    // src={process.env.PUBLIC_URL + "./MobileAssets/Location.png"}
                    src={locationIcon}
                    alt={"name"}
                  />
                </div>
                <div>
                  <div
                    style={{
                      color:
                        city === "Select City"
                          ? "#9fa6b2"
                          : location.state !== null
                          ? location.state.path === "3" ||
                            location.state.path === "4"
                            ? "black"
                            : "white"
                          : "black",
                      fontWeight: 400,
                    }}
                  >
                    {city}
                  </div>
                  <CaretDown
                    color={
                      location.state !== null
                        ? location.state.path === "3" ||
                          location.state.path === "4"
                          ? "black"
                          : "white"
                        : "black"
                    }
                    style={{
                      position: "absolute",
                      right: "10px",
                      top: "20px",
                      cursor: "pointer",
                    }}
                  />
                </div>
              </div>
              {cityError === true ? (
                <div className="field-error">Please enter a valid city.</div>
              ) : null}
              {selectPopMobileCity === true ? (
                <div
                  className="popBox"
                  style={{
                    border:
                      location.state !== null
                        ? location.state.path === "3" ||
                          location.state.path === "4"
                          ? "0.5px solid #00000054"
                          : "0.5px solid rgba(255, 255, 255, 0.8)"
                        : "0.5px solid #00000054",
                  }}
                >
                  {cityArray
                    .filter((ci) => ci.value !== city)
                    .map((ci) => {
                      return (
                        <div
                          className="selectedValue"
                          style={{
                            height: "43px",
                            cursor: "pointer",
                            display: "flex",
                            alignItems: "center",
                            fontWeight: 500,
                            color:
                              location.state !== null
                                ? location.state.path === "3" ||
                                  location.state.path === "4"
                                  ? "black"
                                  : "white"
                                : "black",
                          }}
                          onClick={() => {
                            setCity(ci.value);
                            setSelectPopMobileCity(false);
                            setCityError(false);
                          }}
                        >
                          {ci.value}
                        </div>
                      );
                    })}
                </div>
              ) : null}
              <div
                className="input-container-img-inputBox"
                style={{
                  border:
                    emailError === false
                      ? location.state !== null
                        ? location.state.path === "3" ||
                          location.state.path === "4"
                          ? "0.5px solid #00000054"
                          : "0.5px solid rgba(255, 255, 255, 0.8)"
                        : "0.5px solid #00000054"
                      : "0.5px solid #fe0000",
                }}
              >
                <div className="img-container">
                  <img
                    // src={process.env.PUBLIC_URL + "./MobileAssets/Email.png"}
                    src={emailIcon}
                    alt={"name"}
                  />
                </div>
                <input
                  type="email"
                  className="inputBox"
                  value={email}
                  onBlur={() => handleOnChange(email)}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Your email"
                  aria-label="email"
                  aria-describedby="basic-addon1"
                  style={{
                    color:
                      location.state !== null
                        ? location.state.path === "3" ||
                          location.state.path === "4"
                          ? "black"
                          : "white"
                        : "black",
                  }}
                />
              </div>

              {emailError === true ? (
                <div className="field-error">
                  Please enter a valid email address.
                </div>
              ) : null}
              <div
                className="input-container-img-inputBox"
                style={{
                  border: `${
                    phoneError === true
                      ? "0.5px solid #fe0000"
                      : location.state !== null
                      ? location.state.path === "3" ||
                        location.state.path === "4"
                        ? "0.5px solid #00000054"
                        : "0.5px solid rgba(255, 255, 255, 0.8)"
                      : "0.5px solid #00000054"
                  }`,
                }}
              >
                <div className="img-container">
                  <img
                    // src={process.env.PUBLIC_URL + "./MobileAssets/phoneIcon.png"}
                    src={contactIcon}
                    alt={"phone"}
                  />
                </div>
                <input
                  type="text"
                  placeholder="Phone number"
                  onChange={(e) => {
                    setNumber(e.target.value);
                    checkNumber(e.target.value);
                  }}
                  aria-label="phone"
                  Value={number}
                  onBlur={(e) => checkNumber(e.target.value)}
                  aria-describedby="basic-addon1"
                  style={{
                    color:
                      location.state !== null
                        ? location.state.path === "3" ||
                          location.state.path === "4"
                          ? "black"
                          : "white"
                        : "black",
                  }}
                />
              </div>
              {phoneError === true ? (
                <div className="field-error">
                  Please enter a valid phone number.
                </div>
              ) : null}
              {voucherDiscount === "" && (
                <div
                  className="input-container-img-inputBox"
                  style={{
                    border: `${
                      voucherError === true
                        ? "0.5px solid #fe0000"
                        : location.state !== null
                        ? location.state.path === "3" ||
                          location.state.path === "4"
                          ? "0.5px solid #00000054"
                          : "0.5px solid rgba(255, 255, 255, 0.8)"
                        : "0.5px solid #00000054"
                    }`,
                    backgroundColor:
                      voucherError === ""
                        ? "transparent"
                        : "rgba(252, 0, 0, 0.13)",
                  }}
                >
                  <div className="img-container">
                    <img
                      // src={process.env.PUBLIC_URL + "./MobileAssets/voucher.png"}
                      src={couponIcon}
                      alt={"name"}
                    />
                  </div>
                  <input
                    type="text"
                    placeholder="Voucher Code"
                    ref={voucherCodePhone}
                    style={{
                      backgroundColor: `${
                        voucherError === true
                          ? "0.5px solid #fe0000"
                          : location.state !== null
                          ? location.state.path === "3" ||
                            location.state.path === "4"
                            ? "0.5px solid #00000054"
                            : "0.5px solid rgba(255, 255, 255, 0.8)"
                          : "0.5px solid rgba(255, 255, 255, 0.8)"
                      }`,
                      color: voucherError === "" ? "#000" : "#fe0000",
                      placeholderColor:
                        voucherError === "" ? "#000" : "#fe0000",
                    }}
                    aria-describedby="basic-addon1"
                  />
                  {applyBtn !== "processing..." && (
                    <img
                      className="applyIcon"
                      style={{
                        cursor: "pointer",
                        pointerEvents: `${
                          applyBtn === "processing..." ? "none" : ""
                        }`,
                      }}
                      src={tickIcon}
                      onClick={() => getVoucherStataus(voucherCodePhone)}
                    />
                  )}
                  {applyBtn === "processing..." && (
                    <div role="status" className="mSummary-vocher-applyIcon">
                      <svg
                        aria-hidden="true"
                        class="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                        viewBox="0 0 100 101"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                          fill="currentColor"
                        />
                        <path
                          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                          fill="currentFill"
                        />
                      </svg>
                      <span class="sr-only">Loading...</span>
                    </div>
                  )}
                </div>
              )}
              {voucherError !== "" && (
                <div
                  style={{ color: "#fe0000", fontSize: "14px" }}
                  className="voucher-error"
                >
                  {voucherError}
                </div>
              )}
              {voucherDiscount !== "" && (
                <Captcha
                  setCaptchaSuccess={setCaptchaSuccess}
                  captcha={captcha}
                  setCaptcha={setCaptcha}
                  captchaEnter={captchaEnter}
                  setCaptchaEnter={setCaptchaEnter}
                  captchaError={captchaError}
                />
              )}
              {voucherDiscount !== "" && (
                <div className="wab-input-msummary-vocher">
                  <div
                    className="input-group mb-1 custom voucher-successfully-applied"
                    style={{
                      border: " 0.5px solid rgba(0, 243, 127, 0.80)",
                      backgroundColor: "rgb(30 223 72 / 22%)",
                      justifyContent: "left",
                    }}
                  >
                    <div className="input-group-prepend vocher-input">
                      <img
                        className="mSummary-user-img"
                        // src={process.env.PUBLIC_URL + "./MobileAssets/voucher.png"}
                        src={couponIcon}
                        alt={"name"}
                      />
                    </div>
                    {voucherDiscount}
                    <span
                      style={{
                        position: "absolute",
                        right: "10px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setVoucherDiscount("");
                        setVoucherData({});
                        setVoucherDisclamair("");
                        setDiscount(0);
                      }}
                    >
                      x
                    </span>
                  </div>
                </div>
              )}
              {voucherDisclamair !== "" && (
                <div className="wab-input-msummary-vocher">
                  <div
                    className="mb-1"
                    style={{
                      border: " 0.5px solid #e1e1e1",
                      backgroundColor: "rgb(209 201 201 / 34%)",
                      height: "auto",
                      width: "90%",
                      margin: "auto",
                      marginTop: "14px",
                      borderRadius: "6px",
                      display: "flex",
                      alignItems: "center",
                      gap: "12px",
                      padding: "6px 10px",
                    }}
                  >
                    <img
                      className="mSummary-user-img"
                      // src={process.env.PUBLIC_URL + "./MobileAssets/voucher.png"}
                      src={disclamirIcon}
                      alt={"name"}
                    />

                    <div
                      style={{
                        color: "#000",
                        fontFamily: "Manrope",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "19px",
                        //  textAlign:"justify"
                      }}
                    >
                      {voucherDisclamair}
                    </div>
                  </div>
                </div>
              )}
              {voucherConsumeError !== "" && (
                <div
                  style={{ color: "#fe0000", fontSize: "14px" }}
                  className="voucher-error"
                >
                  {voucherConsumeError}
                </div>
              )}
            </form>
          </div>
          <PayButton
            botherrorE={botherrorE}
            botherrorP={botherrorP}
            location={location}
            wabLoad={wabLoad}
            email={email}
            number={number}
            setCityError={setCityError}
            setNameError={setNameError}
            handleOnChange={handleOnChange}
            checkNumber={checkNumber}
            captchaEnter={captchaEnter}
            captcha={captcha}
            voucherData={voucherData}
            consumeVocherCode={consumeVocherCode}
            registerLeadAndLink={registerLeadAndLink}
            data={data}
            city={city}
            setCaptchaError={setCaptchaError}
            voucherCodePhone={voucherCodePhone}
            name={name}
            match={window.matchMedia("(max-width: 460px)").matches}
          />
        </div>
        {pageshow && (
          <div className="planDetailMobile">
            <PlanDetail
              show={false}
              setDefaultSelectedPlan={setDefaultSelectedPlan}
              discount={Discount}
              referal={data.refferal}
            />
          </div>
        )}
      </div>
      {pageshow && <Footer />}
    </div>
  );
}
